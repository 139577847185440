import React from 'react';
import classNames from 'classnames';
import styles from './Article.module.scss';

const Article = ({ children, aside, isLeftAligned }) => {
    const className = classNames(styles.article, {
        [styles.isLeftAligned]: isLeftAligned
    });

    return (
        <div className={className}>
            { aside &&
                <aside className={styles.aside}>
                    {aside}
                </aside>
            }

            <div className={styles.content}>
                {children}
            </div>            
        </div>
    )
}

Article.propTypes = {

};

export default Article;
