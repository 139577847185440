import React from 'react';
import styles from './MasonryGrid.module.scss';

const MasonryGrid = ({ children }) => {
  return (
    <div className={styles.masonryGrid}>
      {children}
    </div>
  )
}

MasonryGrid.propTypes = {
  
};

const MasonryGridItem = ({ children }) => {
  return (
    <div className={styles.masonryGridItem}>
      {children}
    </div>
  )
}

MasonryGridItem.propTypes = {
  
};

export { MasonryGrid, MasonryGridItem };
