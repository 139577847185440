import React from 'react';
import classNames from 'classnames';
import { themes } from "../../context/ThemeContext";
import Section from '../Section/Section';
import AssetZenImage from '../UI/AssetZenImage/AssetZenImage';
import VideoBg from '../VideoBg/VideoBg';
import styles from './Cover.module.scss';

const Cover = ({ children, image, video }) => {
    const isVector = image && 
        ((image.src && image.src.extension === 'svg') ||
        (typeof image.src === 'string' && image.src.split('.').pop() === 'svg'));

    const className = classNames(styles.cover, {
        [styles.isSVG]: isVector
    });

    return (
        <Section className={className} theme={themes.DARK} isUnstyled={true}>
            <div className={styles.inner}>
                {children}
            </div>

            {image && image.id && !video &&
                <AssetZenImage className={styles.img} id={image.id} width="1600" height="800" style={{ position: 'absolute' }} />
            }

            {image && image.src && !image.id && !video &&
                <img className={image.fit && image.fit === 'contain' ? styles.imgContain : styles.img} style={{ objectPosition: image.position }} src={image.src.publicURL || image.src} alt="" loading="lazy"/>
            }

            {!image && video && video.src &&
                <VideoBg className={styles.video} src={video.src} timestamps={video.timestamps} />
            }

        </Section>
    )
};

export default Cover;
