import React from 'react';
import { graphql } from 'gatsby';
import { themes } from "../context/ThemeContext";
import Layout from '../components/Layout';
import SectionRenderer from '../components/SectionRenderer/SectionRenderer';
import Section from '../components/Section/Section';
import SectionFeature from '../components/SectionFeature/SectionFeature';
import Hgroup from '../components/UI/Hgroup/Hgroup';
import Content, { HTMLContent } from '../components/Content';
import Cover from '../components/Cover/Cover';
import Article from '../components/Article/Article';
import { MasonryGrid, MasonryGridItem } from '../components/UI/MasonryGrid/MasonryGrid';
import { Grid, GridItem } from '../components/UI/Grid/Grid';
import WorkItem from '../components/WorkItem/WorkItem';
import parseMarkdown from '../utils/parseMarkdown';
import Fade from 'react-reveal/Fade';
import type from '../styles/type.module.scss';

export const GenericPageTemplate = ({
    page,
    related,
    sections,
    contentComponent
}) => {
    const PageContent = contentComponent || Content;
    const hasImage = 
        (page.image && (page.image.id || page.image.src)) || 
        (typeof page.image === 'string');

    return (
        <>  
            {hasImage ?
                <>
                    <Cover image={page.image} video={page.video}>
                        <Fade bottom>
                            <Grid>
                                <GridItem lg={10}>
                                    <Hgroup lead={page.name} title={page.title} isReverse={true} isPrimary={true} />
                                </GridItem>
                            </Grid>

                        
                            {page.intro &&
                                <Grid>
                                    <GridItem lg={6}>
                                        <p><strong>{page.intro}</strong></p>
                                    </GridItem>
                                </Grid>
                            }
                        </Fade>
                    </Cover>

                    {page.html && 
                        <Section theme={themes.LIGHT} isCompact={true}>
                            <Article>
                                <PageContent content={page.html} />
                            </Article>
                        </Section>
                    }
                </>
                :
                <Section theme={themes.LIGHT} isCompact={true}>
                    <Hgroup lead={page.name} title={page.title} isPrimary={true} />

                    {page.html && 
                        <Article isLeftAligned={true}>
                            <PageContent content={page.html} />
                        </Article>
                    }
                </Section>
            }
            
            {page.contentSections && page.contentSections.filter( section => section.title ).map( (section, index) => (
                <SectionFeature {...section} contentComponent={PageContent} key={index}/>
            ))}


            {related.length > 0 &&
                <Section Tag="aside" theme={themes.LIGHT} isCompact={true} containerStyle="SECONDARY">
                    <h2 className={type.sectionHeading}>
                        Related projects
                    </h2>

                    <MasonryGrid>
                        {related.map( ({node}) => {
                            return (
                                <MasonryGridItem key={node.id}>
                                    <WorkItem 
                                        {...node.frontmatter}
                                        slug={node.fields.slug} />
                                </MasonryGridItem>
                            )
                        })}
                    </MasonryGrid>
                </Section>
            }

            {sections &&
                <SectionRenderer data={sections && parseMarkdown(sections)} />
            }
            
        </>
    )
};

const GenericPage = ({ data: { page, related } }) => {
    return (
        <Layout 
            meta={page.frontmatter.meta || false}
            title={page.frontmatter.title || page.frontmatter.name || false}
            theme={themes.DARK}
        >
            <GenericPageTemplate
                page={parseMarkdown(page)} 
                related={ related.edges }
                contentComponent={HTMLContent}/>
        </Layout>
    )
};

export default GenericPage;

export const pageQuery = graphql`
  ## Query for GenericPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query GenericPageTemplate($id: String!, $tags: [String]) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Sections
      frontmatter {
        name
        title
        intro
        image {
            id
            src {
                publicURL
                extension
            }
            position
            fit
        }
        video {
            src
            timestamps
        }
        tags {
            value
            label
        }
        contentSections {
            name
            title
            desc
            image {
                position
                id
                src {
                    publicURL
                    extension
                }
            }
            link {
                text
                url
            }
            containerStyle
        }
      }
      html
    }
    related: allMarkdownRemark(
        limit: 4
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { 
            id: { ne: $id },
            frontmatter: { 
                template: {eq: "WorkItem"}
                isPublished: { ne: false }
                tags: {
                    elemMatch: { 
                        value: { in: $tags }
                    }
                }
            } 
        }
    ) {
        edges {
            node {
                id
                fields {
                    slug
                }
                frontmatter {
                    name
                    title
                    image {
                        id
                        src {
                            publicURL
                            extension
                        }
                        fit
                        position
                    }
                    tags {
                        value
                        label
                    }
                }
            }
        }
     }
  }
`
