import React from 'react';
import Link from '../UI/Link/Link';
import AssetZenImage from '../UI/AssetZenImage/AssetZenImage';
import Fade from 'react-reveal/Fade';
import styles from './WorkItem.module.scss';

const WorkItem = ({ name, title, slug, image, tags }) => {
    return (
        <Fade bottom>
            <article className={styles.workItem}>
                <Link to={slug} aria-label={title}>
                    { image.id && 
                        <AssetZenImage className={styles.img} id={image.id} width="588" height="770" alt=""/>
                    }

                    { !image.id && image.src && 
                        <img className={styles.img} src={image.src.publicURL || image.src} width="588" height="770" alt="" loading="lazy"/>
                    }
                </Link>

                <header className={styles.header}>
                    <p className={styles.client}>{name}</p>
                    <h2 className={styles.heading}>
                        <Link to={slug}>{title}</Link>
                    </h2>
                </header>

                <footer className={styles.footer}>
                    {tags.length > 0 &&
                        <ul className={styles.tags}>
                            {tags.map(item => <li key={item.value}>{item.label}</li>)}
                        </ul>
                    }
                </footer>
            </article>
        </Fade>
    )
}

WorkItem.propTypes = {

};

export default WorkItem;
