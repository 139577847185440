import React from 'react';
import classNames from 'classnames';
import styles from './Grid.module.scss';

const Grid = ({ children, isFullWidth, hasGutters, ...other }) => {
  const className = classNames( styles.grid, {
    [styles.gridWithGutters]: hasGutters
  }, other.className );

  return (
    <div className={className}>
      {children}
    </div>
  )
};

Grid.defaultProps = {
  hasGutters: true
};

Grid.propTypes = {
  
};

const GridItem = ({ Tag, children, span, sm, md, lg, xl, xxl, ...other }) => {
  const className = classNames( styles.gridItem, {
    [ styles[ 'colSpanSm' + sm ] ] : !!sm,
    [ styles[ 'colSpanMd' + md ] ] : !!md,
    [ styles[ 'colSpanLg' + lg ] ] : !!lg,
    [ styles[ 'colSpanXl' + xl ] ] : !!xl,
    [ styles[ 'colSpanXxl' + xxl ] ] : !!xxl
  },
  other.className );

  return (
    <Tag className={className}>
      {children}
    </Tag>
  )
};

GridItem.defaultProps = {
  Tag: 'div'
};

GridItem.propTypes = {
  
};

export { Grid, GridItem };
